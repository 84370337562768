import React from 'react';
import './styles.scss';
import { Link } from 'gatsby';

export const Footer = () => {
  return (
    <footer>
      <span>
        Copyright {new Date().getFullYear()} © <Link to="/">Girls To Code</Link>
        {' | '}
        <Link to="/privacy" className="link--white">
          Privacy Policy
        </Link>
      </span>
    </footer>
  );
};
