import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import '../styles.scss';

export const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-pink.png" }) {
        childImageSharp {
          fixed(width: 130, height: 56) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Link to="/">
      <Img
        className="appbar__logo"
        fixed={data.logo.childImageSharp.fixed}
        alt="Girls to Code logo"
      />
    </Link>
  );
};
