import React from 'react';
import classNames from 'classnames';
import '../styles.scss';
import { Link } from 'gatsby';

export const Links = ({ isInvisible }) => {
  const itemsClassName = classNames(
    isInvisible && 'appbar__items--invisible',
    'appbar__items'
  );

  return (
    <ol className={itemsClassName}>
      <li className="appbar__item">
        <Link className="item__link" to="/#about">
          About
        </Link>
      </li>
      <li className="appbar__item">
        <Link className="item__link" to="/#team">
          Team
        </Link>
      </li>
      <li className="appbar__item">
        <Link className="item__link" to="/#partners">
          Partners
        </Link>
      </li>
      <li className="appbar__item">
        <Link className="item__link" to="/#contact">
          Contact
        </Link>
      </li>
      <li className="appbar__item">
        <Link className="item__link" to="/blog">
          Blog
        </Link>
      </li>
    </ol>
  );
};
