import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Appbar } from '../Appbar';
import { Footer } from '../Footer';

export const Layout = ({ children, isShifted, hasTopMenu }) => {
  return (
    <div className="page-container">
      <div className="page-container__content">
        <Appbar hasTopMenu={hasTopMenu} />
        <main className={isShifted && 'content--shifted'}>{children}</main>
      </div>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isShifted: PropTypes.bool,
  hasTopMenu: PropTypes.bool,
};

Layout.defaultProps = {
  hasTopMenu: true,
};
