import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#cc5f6c' },
    secondary: { main: '#4389c5' },
  },
});

export default theme;
