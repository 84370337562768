import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import classNames from 'classnames';
import logoPinkWhite from '../../images/logo-pink-white.png';

export const PostThumbnail = ({ image, size, className }) => {
  const postThumbnailClass = classNames(
    'post-thumbnail',
    `post-thumbnail--${size}`,
    className
  );

  return (
    <div className={postThumbnailClass}>
      <div
        className="post-thumbnail__image-container"
        style={{ backgroundImage: `url(${image || logoPinkWhite})` }}
      />
    </div>
  );
};

PostThumbnail.propTypes = {
  image: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'big']),
};

PostThumbnail.defaultProps = {
  size: 'small',
};
