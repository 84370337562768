import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Links } from './Links';
import { Logo } from './Logo';
import { AppbarWrapper } from './AppbarWrapper';
import { Close, Menu } from 'mdi-material-ui';

export const Appbar = ({ hasTopMenu }) => {
  const [isOpenLinks, setOpenLinks] = React.useState(false);
  const MobileIcon = isOpenLinks ? Close : Menu;

  return (
    <AppbarWrapper>
      <nav className="appbar">
        <Logo />
        {hasTopMenu && (
          <>
            <MobileIcon
              className="appbar__icon"
              fontSize="large"
              onClick={() => setOpenLinks(!isOpenLinks)}
            />
            <Links isInvisible={!isOpenLinks} />
          </>
        )}
      </nav>
    </AppbarWrapper>
  );
};

Appbar.propTypes = {
  hasTopMenu: PropTypes.bool,
};
