import React from 'react';
import classNames from 'classnames';
import '../styles.scss';

export const AppbarWrapper = ({ children }) => {
  const [isHidden, setHidden] = React.useState(false);
  const [isFixed, setFixed] = React.useState(false);
  const lastScrollY = React.useRef(null);

  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      currentScrollY > lastScrollY.current &&
      currentScrollY > window.screen.height
        ? setHidden(true)
        : setHidden(false);

      currentScrollY > window.screen.height &&
      currentScrollY < lastScrollY.current
        ? setFixed(true)
        : setFixed(false);

      lastScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const appbarClassName = classNames(
    isHidden && 'appbar-wrapper--hidden',
    isFixed && 'appbar-wrapper--fixed',
    'appbar-wrapper'
  );

  return <div className={appbarClassName}>{children}</div>;
};
