import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import classNames from 'classnames';

export const Section = ({
  children,
  isFullHeight,
  isFullMobileWidth,
  isWhite,
  isNotDefault,
  className,
  id,
}) => {
  const computedClass = classNames(
    isFullHeight && 'section--full',
    isFullMobileWidth && 'section--fullMobileWidth',
    isWhite && 'section--white',
    !isNotDefault && 'section',
    className
  );
  return (
    <section id={id} className={computedClass}>
      {isNotDefault ? (
        children
      ) : (
        <div className="section__items">{children}</div>
      )}
    </section>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  isNotDefault: PropTypes.bool,
  isFullHeight: PropTypes.bool,
  isWhite: PropTypes.bool,
  isFullMobileWidth: PropTypes.bool,
};

Section.defaultProps = {
  isNotDefault: false,
  isMobileFullWidth: false,
};
